<template>
  <OHomepageOffer
    :title="products?.title"
    :popular-categories="categories"
    :products="products?.productsList"
    :loading="loading"
    banner-position="left"
    :banner-src="getImageUrlWithSize(bannerUrl, sizes)"
    :mobile-banner-src="getImageUrlWithSize(bannerUrl, sizes, true)"
    banner-link="champagnes-and-sparkling"
  />
</template>

<script setup lang="ts">
import { defineComponent } from 'vue'
import { useBanners } from '@/stores/banners'
import { useProductCollections } from '@/stores/product-collection'
import { getImageUrlWithSize } from '@/utils/url'

import type { PopularCategoryExtended } from '@/components/atoms/PopularCategory/types'
import type { BannerCollection } from '@/modules/nuxt-api/models/Banner'

import OHomepageOffer from '@/components/organisms/HomepageOffer/OHomepageOffer.vue'

defineComponent({ name: 'HomeBestsellersBannerCollections' })

const sizes = {
  mobile: { w: '991', h: '344' },
  desktop: { w: '320', h: '638' }
}

const loading = ref(process.client)
const bannerUrl = ref()
const categories = ref()
const products = ref()

function getCategories (): PopularCategoryExtended[] {
  return [
    {
      id: 0,
      title: 'Вина Италии',
      image: {
        url: '/assets/images/popular-sections-index/11_110x110.png',
        mobileUrl:
      '/assets/images/popular-sections-index/11_110x110.png 110w, /assets/images/popular-sections-index/11_276x276.png 276w',
        alt: ''
      },
      displayType: 0,
      path: { path: 'wine/italy' }
    },
    {
      id: 1,
      title: 'Французское шампанское',
      image: {
        url: '/assets/images/popular-sections-index/3_110x110.png',
        mobileUrl:
      '/assets/images/popular-sections-index/3_110x110.png 110w, /assets/images/popular-sections-index/3_276x276.png 276w',
        alt: ''
      },
      displayType: 0,
      path: { path: 'champagnes-and-sparkling/france' }
    },
    {
      id: 2,
      title: 'Русская водка',
      image: {
        url: '/assets/images/popular-sections-index/8_110x110.png',
        mobileUrl:
      '/assets/images/popular-sections-index/8_110x110.png 110w, /assets/images/popular-sections-index/8_276x276.png 276w',
        alt: ''
      },
      displayType: 0,
      path: { path: 'vodka/russia' }
    },
    {
      id: 3,
      title: 'Японский виски',
      image: {
        url: '/assets/images/popular-sections-index/9_110x110.png',
        mobileUrl:
      '/assets/images/popular-sections-index/9_110x110.png 110w, /assets/images/popular-sections-index/9_276x276.png 276w',
        alt: ''
      },
      displayType: 0,
      path: { path: 'whisky/japan' }
    },
    {
      id: 4,
      title: 'Армянский коньяк',
      image: {
        url: '/assets/images/popular-sections-index/6_110x110.png',
        mobileUrl:
      '/assets/images/popular-sections-index/6_110x110.png 110w, /assets/images/popular-sections-index/6_276x276.png 276w',
        alt: ''
      },
      displayType: 0,
      path: { path: 'cognac/armenia' }
    },
    {
      id: 5,
      title: 'Бельгийское пиво',
      image: {
        url: '/assets/images/popular-sections-index/1_110x110.png',
        mobileUrl:
      '/assets/images/popular-sections-index/1_110x110.png 110w, /assets/images/popular-sections-index/1_276x276.png 276w',
        alt: ''
      },
      displayType: 0,
      path: { path: 'beer/belgium' }
    }
  ]
}

await Promise.all([
  useHydrationData('home-banners', () => useBanners().getBannersForHome(), {
    then: ({ data }) => {
      bannerUrl.value = data.value?.filter?.((banner: BannerCollection) => banner.type === 'newlfvert')?.[0]?.bannersList?.[0]?.image?.url
    },
    catch: () => undefined,
    lazy: true
  }),
  useHydrationData('home-bestsellers-categories', getCategories, {
    then: ({ data }) => {
      categories.value = data.value
    },
    catch: () => undefined,
    lazy: true
  }),
  useHydrationData('home-product-collections', () => useProductCollections().getHomeCollections(), {
    then: ({ data }) => {
      products.value = data.value?.[2]
    },
    catch: () => undefined,
    lazy: true
  })
])
  .then(() => { loading.value = false })
</script>
